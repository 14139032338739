import "./App.css";
import Header from "./components/Header";
import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider, createTheme } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import FAQ from "./components/FAQ";
import Play from "./components/Play";
import { SnackbarProvider } from "notistack";
import GameContextProvider from "./components/GameContext";

const theme = createTheme({});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <GameContextProvider>
          <Router>
            <Header />
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/play" element={<Play />} />
            </Routes>
          </Router>
        </GameContextProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
