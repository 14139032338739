import React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

function CodeBreakerBoard({
  guessesAllowed,
  guesses,
  code,
  answer,
  boardSize,
  keyMap,
}) {
  const theme = useTheme();

  const renderSquare = (i, j, codeToRender) => {
    let valueToRender = "";
    if (j < codeToRender.length) {
      valueToRender = codeToRender[j];
    } else {
      valueToRender = "Blank";
    }
    let childToRender = "";
    if (Object.keys(keyMap).includes(valueToRender)) {
      childToRender = keyMap[valueToRender];
    }
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: theme.spacing(1),
        }}
        key={i * boardSize + j}
      >
        {childToRender}
      </Box>
    );
  };

  const calculateHints = (i) => {
    const guess = guesses[i] ? guesses[i] : Array(boardSize).fill("0");
    let numCorrect = 0;
    let numAlmostCorrect = 0;
    let remainingGuess = [];
    let remainingAnswer = [];
    let remainingGuessCounts = {};
    let remainingAnswerCounts = {};
    for (let i = 0; i < guess.length; i++) {
      if (guess[i] === answer[i]) {
        numCorrect++;
      } else {
        remainingGuess.push(guess[i]);
        remainingAnswer.push(answer[i]);
      }
    }
    for (let c of remainingGuess) {
      remainingGuessCounts[c] = remainingGuessCounts[c]
        ? remainingGuessCounts[c] + 1
        : 1;
    }
    for (let c of remainingAnswer) {
      remainingAnswerCounts[c] = remainingAnswerCounts[c]
        ? remainingAnswerCounts[c] + 1
        : 1;
    }
    for (let c of Object.keys(remainingGuessCounts)) {
      if (Object.keys(remainingAnswerCounts).includes(c)) {
        numAlmostCorrect += Math.min(
          remainingAnswerCounts[c],
          remainingGuessCounts[c]
        );
      }
    }
    return { numCorrect, numAlmostCorrect };
  };

  const renderHints = (i) => {
    const { numCorrect, numAlmostCorrect } = calculateHints(i);
    const gridSize = Math.floor(Math.sqrt(boardSize));
    const correctArray = new Array(numCorrect).fill("");
    const almostCorrectArray = new Array(numAlmostCorrect).fill("");
    const notCorrectArray = new Array(
      boardSize - numCorrect - numAlmostCorrect
    ).fill("");
    const correctComps = correctArray.map((val, index) => (
      <Box
        sx={{
          backgroundColor: theme.palette.success.main,
          borderStyle: "solid",
          borderColor: "black",
        }}
        key={"hint-correct-" + index}
      />
    ));
    const almostCorrectComps = almostCorrectArray.map((val, index) => (
      <Box
        sx={{
          backgroundColor: theme.palette.warning.main,
          borderStyle: "solid",
          borderColor: "black",
        }}
        key={"hint-almost-correct-" + index}
      />
    ));
    const notCorrectComps = notCorrectArray.map((val, index) => (
      <Box
        sx={{
          backgroundColor: "white",
          borderStyle: "solid",
          borderColor: "black",
        }}
        key={"hint-not-correct-" + index}
      />
    ));
    return (
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(" + gridSize + ", 1fr)",
          gridGap: "0px",
          borderStyle: "solid",
          borderColor: "black",
          borderRadius: theme.spacing(0.5),
          margin: theme.spacing(0.5),
        }}
        key={"hints-" + i}
      >
        {correctComps}
        {almostCorrectComps}
        {notCorrectComps}
      </Box>
    );
  };

  const renderBoard = () => {
    const cells = [];
    for (let i = 0; i < guessesAllowed; i++) {
      const codeToRender =
        i < guesses.length ? guesses[i] : i === guesses.length ? code : "";
      if (
        i < guesses.length ||
        (i === guesses.length && guesses[i - 1] !== answer)
      ) {
        for (let j = 0; j < boardSize; j++) {
          cells.push(renderSquare(i, j, codeToRender));
        }
        cells.push(renderHints(i));
      }
    }

    return cells;
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(" + (boardSize + 1) + ", 1fr)",
        gridGap: "0px",
        margin: theme.spacing(2),
      }}
    >
      {renderBoard()}
    </Box>
  );
}

export default CodeBreakerBoard;
