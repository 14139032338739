import React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import PuzzleLink from "./PuzzleLink";

function Home() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        flexWrap: "wrap",
        margin: theme.spacing(5),
      }}
    >
      <PuzzleLink id={"codebreaker4"} name={"CodeBreaker"} />
      <PuzzleLink id={"crosswordle5x5"} name={"CrossWordle 5x5"} />
      <PuzzleLink id={"crosswordle9x9"} name={"CrossWordle 9x9"} />
      <PuzzleLink id={"crosswordle15x15"} name={"CrossWordle 15x15"} />
    </Box>
  );
}

export default Home;
