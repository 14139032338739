import React from "react";
import Button from "@mui/material/Button";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

function CrossWordleKey(props) {
  let colors = {
    correct: "#6BD624",
    almost: "#F9D907",
    used: "#282c34",
    unused: "#FFFFFF",
  };

  const size =
    props.value === "Enter" || props.value === "Back" ? "15%" : "10%";
  return (
    <Button
      style={{}}
      sx={{
        backgroundColor: colors[props.usedState],
        minWidth: size,
        maxWidth: size,
      }}
      variant="outlined"
      onClick={() => props.onPress()}
    >
      {props.value === "Enter" ? (
        <KeyboardReturnIcon />
      ) : props.value === "Back" ? (
        <KeyboardBackspaceIcon />
      ) : (
        props.value
      )}
    </Button>
  );
}

export default CrossWordleKey;
