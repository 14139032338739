import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";

function PuzzleLink({ id, name }) {
  const theme = useTheme();
  return (
    <Link to={"/play?puzzle=" + id}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          margin: theme.spacing(2),
        }}
      >
        <img
          src={id + "_icon_128x128.png"}
          alt={name + " Icon"}
          style={{ width: "128px", height: "128px" }}
        />
        <Typography>{name}</Typography>
      </Box>
    </Link>
  );
}

export default PuzzleLink;
