import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import GameOverDialog from "./GameOverDialog";
import useCodeBreakerState from "../hooks/useCodeBreakerState";
import CodeBreakerBoard from "../components/CodeBreakerBoard";
import CodeBreakerKeyBoard from "../components/CodeBreakerKeyBoard";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import Brightness3Icon from "@mui/icons-material/Brightness3";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness5Icon from "@mui/icons-material/Brightness5";
import Brightness6Icon from "@mui/icons-material/Brightness6";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";

function CodeBreaker({ boardSize }) {
  const theme = useTheme();

  const keyMap = {
    Enter: <KeyboardReturnIcon />,
    Back: <KeyboardBackspaceIcon />,
    1: <Brightness1Icon />,
    2: <Brightness3Icon />,
    3: <Brightness4Icon />,
    4: <Brightness5Icon />,
    5: <Brightness6Icon />,
    6: <Brightness7Icon />,
    Blank: <CircleOutlinedIcon />,
  };

  const {
    loading,
    error,
    state,
    stats,
    guessesAllowed,
    onKeyBoardEntry,
    onCloseGameOver,
    onShare,
  } = useCodeBreakerState(boardSize);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "600px",
        }}
      >
        {loading ? (
          <CircularProgress sx={{ margin: theme.spacing(5) }} size="80%" />
        ) : error ? (
          <h4>There was an error loading the game.</h4>
        ) : (
          <>
            <CodeBreakerBoard
              guessesAllowed={guessesAllowed}
              guesses={state.guesses}
              code={state.code}
              answer={state.answer}
              boardSize={boardSize}
              keyMap={keyMap}
            />
            <CodeBreakerKeyBoard
              keyMap={keyMap}
              onPress={(val) => onKeyBoardEntry(val)}
            />
          </>
        )}
      </Box>
      <GameOverDialog
        open={state.showGameOver}
        onClose={onCloseGameOver}
        won={stats.won}
        score={stats.score}
        onShare={onShare}
      />
    </>
  );
}

export default CodeBreaker;
