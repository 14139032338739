import React, { useContext, useState, useEffect, createContext } from "react";
import axios from "axios";

const GameContext = createContext();

export default function GameContextProvider({ children }) {
  const [dictionary, setDictionary] = useState({});
  useEffect(() => {
    let source = axios.CancelToken.source();
    const instance = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      timeout: 10000,
    });
    instance
      .get(`api/dictionary`)
      .then((res) => {
        const dict = res.data.dictionary;
        setDictionary(dict);
      })
      .catch(function (e) {
        if (axios.isCancel(e)) {
          console.log(`Dictionary fetch request cancelled:${e.message}`);
        } else {
          console.log(
            "Another error happened in Dictionary fetch:" + e.message
          );
        }
      });

    return function () {
      source.cancel("Cancelling in cleanup");
    };
  }, []);
  return (
    <GameContext.Provider
      value={{
        dictionary,
      }}
    >
      {children}
    </GameContext.Provider>
  );
}

export function useGameContext() {
  const context = useContext(GameContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
