import React from "react";
import CrossWordleKey from "./CrossWordleKey";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

const keys = [
  ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
  ["A", "S", "D", "F", "G", "H", "J", "K", "L"],
  ["Enter", "Z", "X", "C", "V", "B", "N", "M", "Back"],
];

function CrossWordleKeyBoard({ word, history, onPress }) {
  const theme = useTheme();
  const renderKey = (i, j) => {
    let charCorrect = false;
    let charAlmostCorrect = false;
    let charUsed = false;

    for (let k = 0; k < history.length; k++) {
      const guess = history[k];
      if (guess.includes(keys[i][j])) {
        charUsed = true;
      }
      for (let m = 0; m < guess.length; m++) {
        const guessChar = guess.charAt(m);
        const actualChar = word.charAt(m);
        if (guessChar === actualChar && guessChar === keys[i][j]) {
          charCorrect = true;
        } else if (
          actualChar === keys[i][j] &&
          guessChar !== actualChar &&
          charUsed
        ) {
          charAlmostCorrect = true;
        }
      }
    }

    let usedState = charCorrect
      ? "correct"
      : charAlmostCorrect
      ? "almost"
      : charUsed
      ? "used"
      : "unused";

    return (
      <CrossWordleKey
        key={keys[i][j]}
        value={keys[i][j]}
        usedState={usedState}
        onPress={() => onPress(keys[i][j])}
      />
    );
  };

  const renderRow = (i) => {
    const cells = [];
    for (let j = 0; j < keys[i].length; j++) {
      cells.push(renderKey(i, j));
    }
    return cells;
  };

  const renderKeyBoard = () => {
    const rows = [];
    for (let i = 0; i < 3; i++) {
      rows.push(
        <Box
          key={i}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {renderRow(i)}
        </Box>
      );
    }
    return rows;
  };

  return <Box sx={{ margin: theme.spacing(5) }}>{renderKeyBoard()}</Box>;
}

export default CrossWordleKeyBoard;
