import React from "react";
import GuessHistory from "./GuessHistory";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useMediaQuery, useTheme } from "@mui/material";
import GameOverDialog from "./GameOverDialog";
import { getCurrentWord } from "../utils/cross-wordle-utils";
import CrossWordleBoard from "./CrossWordleBoard";
import CrossWordleKeyBoard from "./CrossWordleKeyBoard";
import useCrossWordleState from "../hooks/useCrossWordleState";

function CrossWordle({ boardSize }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const {
    loading,
    error,
    state,
    stats,
    guessesPerWord,
    onSquareClick,
    onKeyBoardEntry,
    onCloseGameOver,
    onShare,
  } = useCrossWordleState(boardSize);

  const currentWord =
    state.answer && getCurrentWord(state.cursor, state.direction, state.answer);
  const currentGuess =
    state.answer &&
    getCurrentWord(state.cursor, state.direction, state.squares);
  const currentHistory = state.words[currentWord]
    ? state.words[currentWord].history
    : [];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: isMobile ? "100%" : "50%",
          }}
        >
          {loading ? (
            <CircularProgress sx={{ margin: theme.spacing(5) }} size="80%" />
          ) : error ? (
            <h4>There was an error loading the game.</h4>
          ) : (
            <>
              <CrossWordleBoard
                state={state}
                onSquareClick={(i) => onSquareClick(i)}
                isMobile={isMobile}
              />
              <CrossWordleKeyBoard
                history={currentHistory}
                word={currentWord}
                onPress={(val) => onKeyBoardEntry(val)}
              />
            </>
          )}
        </Box>
        <Box sx={{ width: isMobile ? "100%" : "50%" }}>
          {loading || currentWord === null ? (
            <></>
          ) : (
            <GuessHistory
              word={currentWord}
              history={currentHistory}
              currentGuess={currentGuess}
              numGuessesAllowed={guessesPerWord}
              isMobile={isMobile}
            />
          )}
        </Box>
      </Box>
      <GameOverDialog
        open={state.showGameOver}
        onClose={onCloseGameOver}
        won={stats.gameScore.won}
        score={stats.gameScore.score}
        onShare={onShare}
      />
    </>
  );
}

export default CrossWordle;
