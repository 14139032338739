import React from "react";
import CrossWordle from "./CrossWordle";
import CodeBreaker from "./CodeBreaker";
import Box from "@mui/material/Box";
import { useTheme, useMediaQuery } from "@mui/material";
import { useSearchParams } from "react-router-dom";

function Play() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [searchParams] = useSearchParams();
  const puzzle = searchParams.get("puzzle");
  const puzzleMap = {
    codebreaker4: <CodeBreaker boardSize={4} />,
    crosswordle5x5: <CrossWordle boardSize={5} />,
    crosswordle9x9: <CrossWordle boardSize={9} />,
    crosswordle15x15: <CrossWordle boardSize={15} />,
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      {isMobile ? null : <Box sx={{ width: "20%" }} />}
      {puzzleMap[puzzle]}
      {isMobile ? null : <Box sx={{ width: "20%" }} />}
    </Box>
  );
}

export default Play;
