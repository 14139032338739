import { Box } from "@mui/material";
import React, { useRef, useEffect, useState } from "react";

function Square({
  value,
  active,
  cursorActive,
  charStatus,
  onClick,
  edgeStatus,
}) {
  const [fontSize, setFontSize] = useState("16px");
  let ref = useRef();

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const width = entries[0].contentRect.width;
      setFontSize(0.5 * width + "px");
    });
    observer.observe(ref.current);
    const refVar = ref.current;
    return () => refVar && observer.unobserve(refVar);
  }, []);

  const color =
    value === null
      ? "#282c34"
      : charStatus === "correct"
      ? "#6BD624"
      : charStatus === "almost"
      ? "#F9D907"
      : cursorActive
      ? "#BBBBBB"
      : "#FFFFFF";

  const borderColor =
    value == null ? "#282c34" : active ? "#2B83FF" : "#282c34";

  const borderWidth = active ? "2" : "1.5";

  return (
    <Box
      ref={ref}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "bold",
        borderTop:
          (edgeStatus.includes("top") ? borderWidth * 2 : borderWidth) +
          "px solid",
        borderBottom:
          (edgeStatus.includes("bottom") ? borderWidth * 2 : borderWidth) +
          "px solid",
        borderLeft:
          (edgeStatus.includes("left") ? borderWidth * 2 : borderWidth) +
          "px solid",
        borderRight:
          (edgeStatus.includes("right") ? borderWidth * 2 : borderWidth) +
          "px solid",
        width: "100%",
        aspectRatio: "1",
        fontSize,
        zIndex: active ? "1" : "0",
        opacity: cursorActive && value !== null ? "0.5" : "1",
        backgroundColor: color,
        borderColor: borderColor,
      }}
      onClick={onClick}
      onKeyPress={(e) => {
        e.key === "Enter" && e.preventDefault();
      }}
      color="secondary"
    >
      {value}
    </Box>
  );
}

export default Square;
