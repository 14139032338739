import { useState, useEffect, useCallback, useMemo } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import {
  getCurrentWord,
  arrayEquals,
  dateToKey,
  Direction,
} from "../utils/cross-wordle-utils";
import { useGA4React } from "ga-4-react";
import useLocalStorageState from "./useLocalStorageState";
import { useGameContext } from "../components/GameContext";

function useCrossWordleState(boardSize) {
  const gameName = "crosswordle" + boardSize + "x" + boardSize;
  const guessesPerWord = 6;
  const { dictionary } = useGameContext();

  const { enqueueSnackbar } = useSnackbar();
  const ga4 = useGA4React();

  const [date, setDate] = useState(dateToKey(new Date()));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [state, setState] = useLocalStorageState(gameName + "-state", {
    squares: Array(boardSize * boardSize).fill(null),
    cursor: 0,
    direction: null,
    words: {},
    answer: [],
    showGameOver: false,
    gameOver: false,
    statsCalculated: false,
    date: "",
  });

  const initialStats = useMemo(() => {
    let initStats = {
      gameScore: {
        score: 0,
        won: false,
      },
      scoreHistory: {
        scores: [],
        wins: [],
      },
    };
    for (let i = 2; i <= boardSize; i++) {
      initStats.gameScore[i] = { count: 0, guesses: 0 };
      initStats.scoreHistory[i] = { count: 0, guesses: 0 };
    }
    return initStats;
  }, [boardSize]);

  const [stats, setStats] = useLocalStorageState(
    gameName + "-stats",
    initialStats
  );

  const getNextDirection = useCallback(
    (i) => {
      let direction = state.direction;
      if (state.squares[i] != null) {
        const up = i - boardSize;
        const down = i + boardSize;
        const left = i - 1;
        const right = i + 1;
        let verticalPossible = false;
        let horizontalPossible = false;
        if (up >= 0 && state.squares[up] != null) {
          verticalPossible = true;
        } else if (
          down < boardSize * boardSize &&
          state.squares[down] != null
        ) {
          verticalPossible = true;
        } else {
          verticalPossible = false;
        }

        if (
          left >= 0 &&
          left - (left % boardSize) === i - (i % boardSize) &&
          state.squares[left] != null
        ) {
          horizontalPossible = true;
        } else if (
          right < boardSize * boardSize &&
          right - (right % boardSize) === i - (i % boardSize) &&
          state.squares[right] != null
        ) {
          horizontalPossible = true;
        } else {
          horizontalPossible = false;
        }

        if (direction === Direction.Horizontal) {
          direction = verticalPossible
            ? Direction.Vertical
            : Direction.Horizontal;
        } else if (direction === Direction.Vertical) {
          direction = horizontalPossible
            ? Direction.Horizontal
            : Direction.Vertical;
        } else {
          direction = horizontalPossible
            ? Direction.Horizontal
            : verticalPossible
            ? Direction.Vertical
            : null;
        }
      } else {
        direction = null;
      }
      return direction;
    },
    [state.direction, state.squares, boardSize]
  );

  const setCursor = useCallback(
    (i) => {
      const direction = getNextDirection(i);
      setState({
        ...state,
        cursor: i,
        direction: direction,
      });
    },
    [getNextDirection, setState, state]
  );

  const processKeyInput = useCallback(
    (letter) => {
      const currentWord =
        state.answer &&
        getCurrentWord(state.cursor, state.direction, state.answer);
      const currentGuess =
        state.answer &&
        getCurrentWord(state.cursor, state.direction, state.squares);
      const currentHistory = state.words[currentWord]
        ? state.words[currentWord].history
        : [];
      const alphabet = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      let squares = state.squares.slice();
      let i = state.cursor;
      if (alphabet.includes(letter) && squares[i] != null) {
        squares[i] = letter;
        if (state.direction === Direction.Horizontal) {
          let nextI = i + 1;
          if (
            squares[nextI] != null &&
            nextI - (nextI % boardSize) === i - (i % boardSize)
          ) {
            i = nextI;
          }
        } else if (state.direction === Direction.Vertical) {
          let nextI = i + boardSize;
          if (squares[nextI] != null && nextI < boardSize * boardSize) {
            i = nextI;
          }
        }
        setState({
          ...state,
          cursor: i,
          squares: squares,
        });
      } else if (letter === "BACKSPACE" || letter === "Back") {
        if (squares[i] !== "" && squares[i] !== null) {
          squares[i] = "";
        } else if (squares[i] === "") {
          if (state.direction === Direction.Horizontal) {
            let nextI = i - 1;
            if (
              squares[nextI] != null &&
              nextI - (nextI % boardSize) === i - (i % boardSize)
            ) {
              i = nextI;
              squares[i] = "";
            }
          } else if (state.direction === Direction.Vertical) {
            let nextI = i - boardSize;
            if (squares[nextI] != null && nextI >= 0) {
              i = nextI;
              squares[i] = "";
            }
          }
        }
        setState({
          ...state,
          cursor: i,
          squares: squares,
        });
      } else if (letter === "TAB") {
        let i = state.cursor;
        let words = Object.keys(state.words);
        let idx = words.indexOf(currentWord);
        let nextWord = words[idx + 1 === words.length ? 0 : idx + 1];
        let curWordVert = getCurrentWord(
          i % (boardSize * boardSize),
          Direction.Vertical,
          state.answer
        );
        let curWordHor = getCurrentWord(
          i % (boardSize * boardSize),
          Direction.Horizontal,
          state.answer
        );
        while (curWordVert !== nextWord && curWordHor !== nextWord) {
          i += 1;
          curWordVert = getCurrentWord(
            i % (boardSize * boardSize),
            Direction.Vertical,
            state.answer
          );
          curWordHor = getCurrentWord(
            i % (boardSize * boardSize),
            Direction.Horizontal,
            state.answer
          );
        }
        if (curWordVert === nextWord) {
          setState({
            ...state,
            cursor: i % (boardSize * boardSize),
            direction: Direction.Vertical,
          });
        } else if (curWordHor === nextWord) {
          setState({
            ...state,
            cursor: i % (boardSize * boardSize),
            direction: Direction.Horizontal,
          });
        }
      } else if (letter === "ENTER" || letter === "Enter") {
        // add guess to history
        const isCorrectLength = currentGuess.length === currentWord.length;
        const inGuessList =
          dictionary[currentWord.length].includes(currentGuess);
        const isNewGuess = !currentHistory.includes(currentGuess);
        const hasGuessesLeft = currentHistory.length < guessesPerWord;
        const hasNotGuessedCorrectly = !currentHistory.includes(currentWord);

        let history = [...currentHistory];
        let words = state.words;
        if (
          isCorrectLength &&
          inGuessList &&
          isNewGuess &&
          hasGuessesLeft &&
          hasNotGuessedCorrectly
        ) {
          history.push(currentGuess);
        } else {
          (!isCorrectLength || !inGuessList) &&
            enqueueSnackbar("Guess is not a valid word");
          !isNewGuess &&
            hasNotGuessedCorrectly &&
            enqueueSnackbar("You've already guessed that word!");
          !hasGuessesLeft && enqueueSnackbar("No guesses left for this word");
          !hasNotGuessedCorrectly &&
            enqueueSnackbar("You've already guessed this word correctly");
        }
        words[currentWord].history = history;
        let gameOver = false;
        if (arrayEquals(state.squares, state.answer)) {
          gameOver = true;
        } else {
          let guessesLeft = false;
          for (var word in state.words) {
            let wordHistory = state.words[word].history;
            if (
              wordHistory.length < guessesPerWord &&
              wordHistory[wordHistory.length - 1] !== word
            ) {
              guessesLeft = true;
              break;
            }
          }
          if (!guessesLeft) {
            gameOver = true;
          }
        }
        setState({
          ...state,
          words: words,
          showGameOver: gameOver,
          gameOver: gameOver,
        });
      } else if (letter === "ARROWUP") {
        const nextI = state.cursor - boardSize;
        if (state.squares[nextI] !== null && nextI >= 0) {
          setState({
            ...state,
            direction: Direction.Vertical,
            cursor: nextI,
          });
        }
      } else if (letter === "ARROWDOWN") {
        const nextI = state.cursor + boardSize;
        if (state.squares[nextI] !== null && nextI < boardSize * boardSize) {
          setState({
            ...state,
            direction: Direction.Vertical,
            cursor: nextI,
          });
        }
      } else if (letter === "ARROWLEFT") {
        const nextI = state.cursor - 1;
        if (state.squares[nextI] !== null && nextI >= 0) {
          setState({
            ...state,
            direction: Direction.Horizontal,
            cursor: nextI,
          });
        }
      } else if (letter === "ARROWRIGHT") {
        const nextI = state.cursor + 1;
        if (state.squares[nextI] !== null && nextI < boardSize * boardSize) {
          setState({
            ...state,
            direction: Direction.Horizontal,
            cursor: nextI,
          });
        }
      } else {
        console.log(letter);
      }
    },
    [enqueueSnackbar, setState, state, boardSize, dictionary]
  );

  const onKeyDown = useCallback(
    (val) => {
      val.preventDefault();
      const letter = val.key.toUpperCase();
      processKeyInput(letter);
    },
    [processKeyInput]
  );

  // listen for key strokes
  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [onKeyDown]);

  // load today's answer and reset state
  useEffect(() => {
    if (date !== state.date) {
      setLoading(true);
      setError(false);
      let source = axios.CancelToken.source();
      const instance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        timeout: 10000,
      });
      instance
        .get(`api/answers?date=${date}&puzzle=${gameName}`)
        .then((res) => {
          const answer = res.data.answer;
          let words = {};
          for (let i = 0; i < boardSize * boardSize; i++) {
            const currentHWord = getCurrentWord(
              i,
              Direction.Horizontal,
              answer
            );
            const currentVWord = getCurrentWord(i, Direction.Vertical, answer);
            if (
              currentHWord &&
              currentHWord.length > 1 &&
              !(currentHWord in words)
            ) {
              words[currentHWord] = {
                history: [],
              };
            }
            if (
              currentVWord &&
              currentVWord.length > 1 &&
              !(currentVWord in words)
            ) {
              words[currentVWord] = {
                history: [],
              };
            }
          }

          const squares = answer.map((letter) => (letter !== null ? "" : null));

          setState({
            cursor: 0,
            direction: Direction.Horizontal,
            showGameOver: false,
            gameOver: false,
            statsCalculated: false,
            squares,
            answer,
            words,
            date,
          });
          setLoading(false);
        })
        .catch(function (e) {
          if (axios.isCancel(e)) {
            console.log(`request cancelled:${e.message}`);
          } else {
            console.log("another error happened:" + e.message);
          }
          setError(true);
          setLoading(false);
        });

      return function () {
        source.cancel("Cancelling in cleanup");
      };
    } else {
      setLoading(false);
    }
  }, [
    date,
    state.date,
    setState,
    state.answer,
    boardSize,
    gameName,
    dictionary,
  ]);

  // update the date every 1 second
  useEffect(() => {
    var timer = setInterval(() => {
      let newDate = dateToKey(new Date());
      if (newDate !== date) setDate(newDate);
    }, 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });

  const calculateScoreHistory = useCallback(
    (gameScore) => {
      let scoreHistory = { ...stats.scoreHistory };
      try {
        for (let i = 2; i <= boardSize; i++) {
          scoreHistory[i].count += gameScore[i].count;
          scoreHistory[i].guesses += gameScore[i].guesses;
        }
        scoreHistory.scores.push(gameScore.score);
        scoreHistory.wins.push(gameScore.won);
      } catch {
        //reset stat history if scoreHistory fails
        scoreHistory = initialStats.scoreHistory;
        for (let i = 2; i <= boardSize; i++) {
          scoreHistory[i].count += gameScore[i].count;
          scoreHistory[i].guesses += gameScore[i].guesses;
        }
        scoreHistory.scores.push(gameScore.score);
        scoreHistory.wins.push(gameScore.won);
      }
      return scoreHistory;
    },
    [stats, initialStats.scoreHistory, boardSize]
  );

  const calculateGameScore = useCallback(() => {
    let gameScore = {
      score: 0,
      won: false,
    };
    for (let i = 2; i <= boardSize; i++) {
      gameScore[i] = { count: 0, guesses: 0 };
    }
    for (var word in state.words) {
      const hist = state.words[word].history;
      const guessedCorrectly = hist[hist.length - 1] === word;
      if (guessedCorrectly) {
        gameScore.score += 6.0 / state.words[word].history.length;
      }
      gameScore[word.length].count += 1;
      gameScore[word.length].guesses += state.words[word].history.length;
    }
    gameScore.score /= Object.keys(state.words).length;
    gameScore.won = arrayEquals(state.squares, state.answer);
    return gameScore;
  }, [state.words, state.squares, state.answer, boardSize]);

  //calculate game stats if game is over
  useEffect(() => {
    if (state.gameOver && !state.statsCalculated) {
      let gameScore = calculateGameScore();
      let scoreHistory = calculateScoreHistory(gameScore);
      let newStats = {
        gameScore: gameScore,
        scoreHistory: scoreHistory,
      };
      ga4 &&
        ga4.gtag("event", "game_over", {
          game: gameName,
          score: gameScore.score,
          won: gameScore.won,
        });
      setStats(newStats);
      setState({
        ...state,
        statsCalculated: true,
      });
    }
  }, [
    state,
    stats,
    calculateGameScore,
    calculateScoreHistory,
    setState,
    setStats,
    ga4,
    gameName,
  ]);

  const onSquareClick = (i) => {
    setCursor(i);
  };

  const onKeyBoardEntry = (val) => {
    processKeyInput(val);
  };

  const onCloseGameOver = () =>
    setState({
      ...state,
      showGameOver: false,
    });

  const onShare = () => {
    let text =
      "CrossWordle " + boardSize + "x" + boardSize + ": " + date + "\n";
    text += "Score: " + stats.gameScore.score.toFixed(2) + "\n";
    text += "www.cauzzle.com/play?puzzle=" + gameName;
    navigator.clipboard.writeText(text);
    enqueueSnackbar("Results copied to the clipboard!");
  };

  return {
    loading,
    error,
    state,
    stats,
    guessesPerWord,
    onSquareClick,
    onKeyBoardEntry,
    onCloseGameOver,
    onShare,
  };
}

export default useCrossWordleState;
