import { Box, Typography, useTheme } from "@mui/material";
import React from "react";

function Contact() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        margin: theme.spacing(5),
      }}
    >
      <Typography variant="h1">COMING SOON</Typography>
    </Box>
  );
}

export default Contact;
