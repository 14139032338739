import React from "react";
import Button from "@mui/material/Button";

function Key({ state, value, onPress, keyMap }) {
  let colors = {
    correct: "#6BD624",
    almost: "#F9D907",
    used: "#282c34",
    unused: "#FFFFFF",
  };

  const size = value === "Enter" || value === "Back" ? "15%" : "10%";
  return (
    <Button
      style={{}}
      sx={{
        backgroundColor: colors[state],
        minWidth: size,
        maxWidth: size,
      }}
      variant="outlined"
      onClick={onPress}
    >
      {Object.keys(keyMap).includes(value) ? keyMap[value] : value}
    </Button>
  );
}

export default Key;
