import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ShareIcon from "@mui/icons-material/Share";

export default function GameOverDialog({ open, onClose, onShare, won, score }) {
  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Game Over</DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>{won ? "You won!" : "You lost."}</Typography>
        <Typography gutterBottom>Score: {score.toFixed(2)}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <IconButton autoFocus aria-label="share" onClick={onShare}>
          <ShareIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
}
