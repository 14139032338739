import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  useTheme,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import MailIcon from "@mui/icons-material/Mail";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import InfoIcon from "@mui/icons-material/Info";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    color: theme.palette.primary.contrastText,
    fontSize: "20px",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  activeLink: {
    textDecoration: "none",
    color: theme.palette.primary.dark,
    fontSize: "20px",
    marginLeft: theme.spacing(-2),
    paddingLeft: theme.spacing(3.5),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    borderStyle: "none none none solid",
    borderWidth: theme.spacing(0.5),
    borderColor: theme.palette.primary.dark,
    display: "flex",
    alignItems: "center",
  },
  icon: {
    color: theme.palette.primary.contrastText,
  },
  paper: {
    background: theme.palette.primary.light,
  },
}));

function DrawerComponent() {
  const classes = useStyles();
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <>
      <Drawer
        sx={{
          [`& .MuiDrawer-paper`]: {
            background: theme.palette.primary.light,
          },
        }}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List dense>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <NavLink
                className={(navData) =>
                  navData.isActive ? classes.activeLink : classes.link
                }
                to="/"
              >
                <HomeIcon sx={{ marginRight: theme.spacing(1) }} />
                Home
              </NavLink>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <NavLink
                className={(navData) =>
                  navData.isActive ? classes.activeLink : classes.link
                }
                to="/about"
              >
                <InfoIcon sx={{ marginRight: theme.spacing(1) }} />
                About
              </NavLink>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <NavLink
                className={(navData) =>
                  navData.isActive ? classes.activeLink : classes.link
                }
                to="/contact"
              >
                <MailIcon sx={{ marginRight: theme.spacing(1) }} />
                Contact
              </NavLink>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <NavLink
                className={(navData) =>
                  navData.isActive ? classes.activeLink : classes.link
                }
                to="/FAQ"
              >
                <FactCheckIcon sx={{ marginRight: theme.spacing(1) }} />
                FAQ
              </NavLink>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon className={classes.icon} />
      </IconButton>
    </>
  );
}

export default DrawerComponent;
