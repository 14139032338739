import React from "react";
import Square from "./Square";
import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";

function GuessHistory({
  word,
  history,
  currentGuess,
  numGuessesAllowed,
  isMobile,
}) {
  const theme = useTheme();

  function getEdgeStatus(i, j) {
    let edgeStatus = [];
    if (i === 0) edgeStatus.push("top");
    if (i === numGuessesAllowed - 1) edgeStatus.push("bottom");
    if (j === 0) edgeStatus.push("left");
    if (j === word.length - 1) edgeStatus.push("right");
    return edgeStatus;
  }

  const renderSquare = (i, j) => {
    let value = "";
    let guess = "";
    if (history.length > i) {
      guess = history[i];
      value = history[i].charAt(j);
    } else if (
      history.length === i &&
      currentGuess !== undefined &&
      history[i - 1] !== word
    ) {
      guess = currentGuess;
      value = currentGuess.charAt(j);
    }

    let charCorrect = false;
    let charAlmostCorrect = false;
    if (history.length > i) {
      if (value === word.charAt(j)) {
        charCorrect = true;
      } else {
        for (let k = 0; k < word.length; k++) {
          if (word.charAt(k) === value && guess.charAt(k) !== value) {
            charAlmostCorrect = true;
          }
        }
      }
    }

    return (
      <Square
        key={i * word.length + j}
        value={value}
        charStatus={
          charCorrect ? "correct" : charAlmostCorrect ? "almost" : "wrong"
        }
        edgeStatus={getEdgeStatus(i, j)}
        active={history.length === i && history[i - 1] !== word}
        onClick={() => null}
      />
    );
  };

  const renderRow = (i) => {
    const cells = [];
    for (let j = 0; j < word.length; j++) {
      cells.push(renderSquare(i, j));
    }
    return cells;
  };

  const renderBoard = () => {
    const rows = [];
    for (let i = 0; i < numGuessesAllowed; i++) {
      rows.push(renderRow(i));
    }
    return rows;
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(" + (word ? word.length : 5) + ",1fr)",
        gridGap: "0px",
        marginTop: isMobile ? "0" : theme.spacing(5),
        marginLeft: theme.spacing(5),
        marginRight: theme.spacing(5),
        marginBottom: theme.spacing(5),
      }}
    >
      {word != null ? renderBoard() : <></>}
    </Box>
  );
}

export default GuessHistory;
