import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { dateToKey } from "../utils/cross-wordle-utils";
import { useGA4React } from "ga-4-react";
import useLocalStorageState from "./useLocalStorageState";

function useCodeBreakerState(boardSize) {
  const gameName = "codebreaker" + boardSize;
  const guessesAllowed = 12;

  const { enqueueSnackbar } = useSnackbar();
  const ga4 = useGA4React();

  const [date, setDate] = useState(dateToKey(new Date()));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [state, setState] = useLocalStorageState(gameName + "-state", {
    code: "",
    guesses: [],
    answer: [],
    showGameOver: false,
    gameOver: false,
    statsCalculated: false,
    date: "",
  });

  const [stats, setStats] = useLocalStorageState(gameName + "-stats", {
    score: 0,
    won: false,
  });

  const processKeyInput = useCallback(
    (input) => {
      if (!state.gameOver) {
        let code = state.code.slice();
        const codes = ["1", "2", "3", "4", "5", "6"];
        if (codes.includes(input)) {
          if (code.length === boardSize) {
            code[boardSize - 1] = input;
          } else {
            code += input;
          }
          setState({
            ...state,
            code,
          });
        } else if (input === "BACKSPACE" || input === "Back") {
          code = code.slice(0, -1);
          setState({
            ...state,
            code,
          });
        } else if (input === "ENTER" || input === "Enter") {
          const isCorrectLength = state.code.length === boardSize;
          const isNewGuess = !state.guesses.includes(code);
          const hasGuessesLeft = state.guesses.length < guessesAllowed;
          const hasNotGuessedCorrectly = !state.guesses.includes(state.answer);

          let guesses = [...state.guesses];
          if (
            isCorrectLength &&
            isNewGuess &&
            hasGuessesLeft &&
            hasNotGuessedCorrectly
          ) {
            guesses.push(code);
          } else {
            !isCorrectLength && enqueueSnackbar("Guess is not a valid code");
            !isNewGuess &&
              hasNotGuessedCorrectly &&
              enqueueSnackbar("You've already guessed that code!");
            !hasGuessesLeft && enqueueSnackbar("No guesses left for this code");
            !hasNotGuessedCorrectly &&
              enqueueSnackbar("You've already guessed this code correctly");
          }
          let gameOver = false;
          if (code === state.answer) {
            gameOver = true;
          } else {
            let guessesLeft = guesses.length < guessesAllowed;
            if (!guessesLeft) {
              gameOver = true;
            }
          }
          setState({
            ...state,
            guesses,
            showGameOver: gameOver,
            gameOver,
            code: "",
          });
        }
      }
    },
    [enqueueSnackbar, setState, state, boardSize]
  );

  const onKeyDown = useCallback(
    (val) => {
      val.preventDefault();
      const input = val.key.toUpperCase();
      processKeyInput(input);
    },
    [processKeyInput]
  );

  // listen for key strokes
  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [onKeyDown]);

  // load today's answer and reset state
  useEffect(() => {
    if (date !== state.date) {
      setLoading(true);
      setError(false);
      let source = axios.CancelToken.source();
      const instance = axios.create({
        baseURL: process.env.REACT_APP_BASE_URL,
        timeout: 10000,
      });
      instance
        .get(`api/answers?date=${date}&puzzle=${gameName}`)
        .then((res) => {
          const answer = res.data.answer;
          setState({
            code: "",
            guesses: [],
            answer,
            showGameOver: false,
            gameOver: false,
            statsCalculated: false,
            date,
          });
          setLoading(false);
        })
        .catch(function (e) {
          if (axios.isCancel(e)) {
            console.log(`request cancelled:${e.message}`);
          } else {
            console.log("another error happened:" + e.message);
          }
          setError(true);
          setLoading(false);
        });

      return function () {
        source.cancel("Cancelling in cleanup");
      };
    } else {
      setLoading(false);
    }
  }, [date, state.date, setState, state.answer, boardSize, gameName]);

  // update the date every 1 second
  useEffect(() => {
    var timer = setInterval(() => {
      let newDate = dateToKey(new Date());
      if (newDate !== date) setDate(newDate);
    }, 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });

  const calculateGameScore = useCallback(() => {
    let gameScore = {
      score: 0,
      won: false,
    };
    const numGuesses = state.guesses.length;
    const lastGuess = state.guesses[numGuesses - 1];
    gameScore.won = lastGuess === state.answer;
    gameScore.score = gameScore.won ? guessesAllowed / numGuesses : 0;
    return gameScore;
  }, [state.guesses, state.answer, guessesAllowed]);

  //calculate game stats if game is over
  useEffect(() => {
    if (state.gameOver && !state.statsCalculated) {
      let gameScore = calculateGameScore();
      ga4 &&
        ga4.gtag("event", "game_over", {
          game: gameName,
          score: gameScore.score,
          won: gameScore.won,
        });
      setStats(gameScore);
      setState({
        ...state,
        statsCalculated: true,
      });
    }
  }, [state, stats, calculateGameScore, setState, setStats, ga4, gameName]);

  const onKeyBoardEntry = (val) => {
    processKeyInput(val);
  };

  const onCloseGameOver = () =>
    setState({
      ...state,
      showGameOver: false,
    });

  const onShare = () => {
    let text = "CodeBreaker - " + boardSize + ": " + date + "\n";
    text += "Score: " + stats.score.toFixed(2) + "\n";
    text += "www.cauzzle.com/play?puzzle=" + gameName;
    navigator.clipboard.writeText(text);
    enqueueSnackbar("Results copied to the clipboard!");
  };

  return {
    loading,
    error,
    state,
    stats,
    guessesAllowed,
    onKeyBoardEntry,
    onCloseGameOver,
    onShare,
  };
}

export default useCodeBreakerState;
