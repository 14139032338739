import React from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { NavLink } from "react-router-dom";
import DrawerComponent from "./DrawerComponent";
import HomeIcon from "@mui/icons-material/Home";
import MailIcon from "@mui/icons-material/Mail";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import InfoIcon from "@mui/icons-material/Info";

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(10),
    display: "flex",
  },
  logo: {
    cursor: "pointer",
    textDecoration: "none",
    color: "white",
  },
  spacer: {
    flexGrow: "1",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.contrastText,
    fontSize: "20px",
    marginLeft: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  activeLink: {
    textDecoration: "none",
    color: theme.palette.primary.dark,
    fontSize: "20px",
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(-1.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    borderStyle: "none none solid none",
    borderWidth: theme.spacing(0.5),
    borderColor: theme.palette.primary.dark,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "end",
  },
}));

function Header(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: theme.palette.primary.light }}
    >
      <CssBaseline />
      <Toolbar>
        <NavLink to="/" className={classes.logo}>
          <Box
            sx={{
              width: "48px",
              height: "48px",
              verticalAlign: "middle",
            }}
            component="img"
            src="puzzle128.png"
            alt="Cauzzle Logo"
          />
        </NavLink>
        <Box className={classes.spacer}></Box>
        {isMobile ? (
          <DrawerComponent />
        ) : (
          <div className={classes.navlinks}>
            <NavLink
              to="/"
              className={(navData) =>
                navData.isActive ? classes.activeLink : classes.link
              }
            >
              <HomeIcon />
            </NavLink>
            <NavLink
              to="/about"
              className={(navData) =>
                navData.isActive ? classes.activeLink : classes.link
              }
            >
              <InfoIcon />
            </NavLink>
            <NavLink
              to="/contact"
              className={(navData) =>
                navData.isActive ? classes.activeLink : classes.link
              }
            >
              <MailIcon />
            </NavLink>
            <NavLink
              to="/faq"
              className={(navData) =>
                navData.isActive ? classes.activeLink : classes.link
              }
            >
              <FactCheckIcon />
            </NavLink>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
