import React from "react";
import Box from "@mui/material/Box";
import Key from "./Key";
import { useTheme } from "@mui/material";

const keys = [["Enter", "1", "2", "3", "4", "5", "6", "Back"]];

function CodeBreakerKeyBoard({ onPress, keyMap }) {
  const theme = useTheme();
  const renderKey = (i, j) => {
    return (
      <Key
        key={keys[i][j]}
        onPress={() => onPress(keys[i][j])}
        value={keys[i][j]}
        state={"unused"}
        keyMap={keyMap}
      />
    );
  };

  const renderRow = (i) => {
    const cells = [];
    for (let j = 0; j < keys[i].length; j++) {
      cells.push(renderKey(i, j));
    }
    return cells;
  };

  const renderKeyBoard = () => {
    const rows = [];
    for (let i = 0; i < keys.length; i++) {
      rows.push(
        <Box
          key={i}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {renderRow(i)}
        </Box>
      );
    }
    return rows;
  };

  return <Box sx={{ margin: theme.spacing(2) }}>{renderKeyBoard()}</Box>;
}

export default CodeBreakerKeyBoard;
