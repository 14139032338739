import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import GA4React from "ga-4-react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const ga4react = new GA4React("G-EKK3K6WZSM");

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://a7b45a947f9844eaa5928be9a00c4382@o1226653.ingest.sentry.io/6372269",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

process.env.NODE_ENV === "production" // || process.env.NODE_ENV === "development"
  ? (async () => {
      await ga4react
        .initialize()
        .then((res) => console.log("Analytics success"))
        .catch((err) => console.log("Analytics failure: " + err))
        .finally(() => {
          ReactDOM.render(
            <React.StrictMode>
              <App />
            </React.StrictMode>,
            document.getElementById("root")
          );
        });
    })()
  : ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById("root")
    );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
