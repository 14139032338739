import React from "react";
import Square from "./Square";
import { getCurrentWord, Direction } from "../utils/cross-wordle-utils";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";

function CrossWordleBoard({ state, onSquareClick, isMobile }) {
  const boardSize = Math.sqrt(state.squares.length);
  const theme = useTheme();

  function isSquareActive(i) {
    let active;
    if (state.direction === Direction.Horizontal) {
      const sameRow =
        i - (i % boardSize) === state.cursor - (state.cursor % boardSize);
      if (sameRow) {
        const start = i < state.cursor ? i : state.cursor;
        const end = i >= state.cursor ? i : state.cursor;
        active = true;
        for (let j = start; j <= end; j++) {
          if (state.squares[j] === null) {
            active = false;
          }
        }
      } else {
        active = false;
      }
    } else if (state.direction === Direction.Vertical) {
      const sameCol = i % boardSize === state.cursor % boardSize;
      if (sameCol) {
        const start = i < state.cursor ? i : state.cursor;
        const end = i >= state.cursor ? i : state.cursor;
        active = true;
        for (let j = start; j <= end; j += boardSize) {
          if (state.squares[j] === null) {
            active = false;
          }
        }
      } else {
        active = false;
      }
    } else {
      active = false;
    }
    return active;
  }

  function getCharacterStatus(i) {
    let value = state.squares[i];
    let hWord = getCurrentWord(i, Direction.Horizontal, state.squares);
    let hAnswer =
      state.answer && getCurrentWord(i, Direction.Horizontal, state.answer);
    let vWord = getCurrentWord(i, Direction.Vertical, state.squares);
    let vAnswer =
      state.answer && getCurrentWord(i, Direction.Vertical, state.answer);

    let charCorrect = false;
    let charAlmostCorrect = false;
    if (hAnswer in state.words) {
      let j = i;
      while (state.squares[j] !== null && j % boardSize !== 0) {
        j = j - 1;
      }
      if (state.squares[j] === null) {
        j = j + 1;
      }

      let history = state.words[hAnswer].history;
      if (history.includes(hWord)) {
        if (value === hAnswer.charAt(i - j)) {
          charCorrect = true;
        } else {
          for (let k = 0; k < hWord.length; k++) {
            if (hAnswer.charAt(k) === value && hWord.charAt(k) !== value) {
              charAlmostCorrect = true;
            }
          }
        }
      } else if (history.length > 0) {
        let lastGuess = history[history.length - 1];
        if (
          value === lastGuess.charAt(i - j) &&
          value === hAnswer.charAt(i - j)
        ) {
          charCorrect = true;
        }
      }
    }
    if (
      vAnswer in state.words &&
      state.words[vAnswer].history.includes(vWord)
    ) {
      let j = i;
      while (state.squares[j] !== null && j - boardSize >= 0) {
        j = j - boardSize;
      }
      if (state.squares[j] === null) {
        j = j + boardSize;
      }
      let history = state.words[vAnswer].history;
      if (history.includes(vWord)) {
        if (value === vAnswer.charAt((i - j) / boardSize)) {
          charCorrect = true;
        } else {
          for (let k = 0; k < vWord.length; k++) {
            if (vAnswer.charAt(k) === value && vWord.charAt(k) !== value) {
              charAlmostCorrect = true;
            }
          }
        }
      } else if (history.length > 0) {
        let lastGuess = history[history.length - 1];
        if (
          value === lastGuess.charAt((i - j) / boardSize) &&
          value === vAnswer.charAt((i - j) / boardSize)
        ) {
          charCorrect = true;
        }
      }
    }
    return charCorrect ? "correct" : charAlmostCorrect ? "almost" : "wrong";
  }

  function getEdgeStatus(i) {
    let edgeStatus = [];
    if (i < boardSize) edgeStatus.push("top");
    if (i >= boardSize * boardSize - boardSize) edgeStatus.push("bottom");
    if (i % boardSize === 0) edgeStatus.push("left");
    if (i % boardSize === boardSize - 1) edgeStatus.push("right");
    return edgeStatus;
  }

  const renderSquare = (i) => {
    const active = isSquareActive(i);
    let value = state.squares[i];
    let cursorActive = state.cursor === i;
    const charStatus = getCharacterStatus(i);
    const edgeStatus = getEdgeStatus(i);
    const size = boardSize <= 5 ? "large" : boardSize <= 9 ? "medium" : "small";

    return (
      <Square
        key={i}
        value={value}
        cursorActive={cursorActive}
        active={active}
        charStatus={charStatus}
        edgeStatus={edgeStatus}
        onClick={() => onSquareClick(i)}
        size={size}
      />
    );
  };

  const renderBoard = () => {
    const cells = [];
    for (let i = 0; i < state.squares.length; i++) {
      cells.push(renderSquare(i));
    }
    return cells;
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(" + boardSize + ", 1fr)",
        gridGap: "0px",
        margin: theme.spacing(5),
      }}
    >
      {renderBoard()}
    </Box>
  );
}

export default CrossWordleBoard;
