export const Direction = {
  Horizontal: "horizontal",
  Vertical: "vertical",
};

export function dateToKey(date) {
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString();
  const day = date.getDate().toString();
  return year + "-" + month + "-" + day;
}

export function arrayEquals(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

export function getCurrentWord(i, direction, board) {
  // if current location is null, no current word
  let word;
  const size = Math.sqrt(board.length);
  if (board[i] === null) {
    word = null;
  } else {
    if (direction === Direction.Horizontal) {
      let start = i - (i % size);
      let stop = start + size;
      word = "";
      for (let j = start; j < stop; j++) {
        if (board[j] != null) {
          if (board[j] === "") {
            word += " ";
          } else {
            word += board[j];
          }
        } else {
          if (j > i) {
            break;
          } else {
            word = "";
          }
        }
      }
    } else {
      let start = i % size;
      let stop = size * size - size + start;
      word = "";
      for (let j = start; j <= stop; j += size) {
        if (board[j] != null) {
          if (board[j] === "") {
            word += " ";
          } else {
            word += board[j];
          }
        } else {
          if (j > i) {
            break;
          } else {
            word = "";
          }
        }
      }
    }
    return word;
  }
}
