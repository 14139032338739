import { Box, Typography, useTheme, List } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import { Extension, Share, AttachMoney } from "@mui/icons-material";

function About() {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const onShare = () => {
    let text = "https://www.cauzzle.com";
    navigator.clipboard.writeText(text);
    enqueueSnackbar("Link copied to the clipboard!");
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "90%",
        marginTop: theme.spacing(5),
        maxWidth: theme.spacing(100),
      }}
    >
      <Typography variant="h3">Cauzzle = Cause + Puzzle</Typography>
      <Typography>&nbsp;</Typography>
      <Typography>
        Why does it seem like running is the only way to support a charity by
        doing an unrelated activity? Why can't more of the things we do on a
        regular basis be tied to the causes we want to support? What if I could
        do my daily puzzle and have it count for something?
      </Typography>
      <Typography>&nbsp;</Typography>
      <Typography>
        Cauzzle aims to let you earn points for a charity of your choice, which
        will then be used to determine how to split and donate profits from this
        site.
      </Typography>
      {/* <Typography>&nbsp;</Typography>
      <Typography variant="h5">How it Works</Typography>
      <List sx={{ listStyleType: "decimal" }}>
        <ListItem sx={{ display: "list-item" }}>Choose your cause</ListItem>
        <ListItem sx={{ display: "list-item" }}>Solve a puzzle</ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Earn points for your cause
        </ListItem>
        <ListItem sx={{ display: "list-item" }}>
          Share to get others involved!
        </ListItem>
      </List> */}
      <Typography>&nbsp;</Typography>
      <Typography variant="h5">Current Plan</Typography>
      <Typography>&nbsp;</Typography>
      <Typography>
        This idea only works with a fairly substantial user base. Because of
        that, the priority right now is not to add in features that will make it
        possible to select and play for your specific cause. That is the
        eventual plan, but until there are enough users to allow for
        monetization, there won't be any money available to donate anyways. The
        current priority is growing the user base by creating more puzzles.
      </Typography>
      <Typography>&nbsp;</Typography>
      <Typography variant="h5">How can you help?</Typography>
      <List sx={{ listStyleType: "decimal", listStylePosition: "inside" }}>
        <ListItemButton component={Link} to="/" sx={{ py: 0, minHeight: 32 }}>
          <ListItemIcon sx={{ color: "inherit" }}>
            <Extension />
          </ListItemIcon>
          <ListItemText
            primary="Play a puzzle!"
            primaryTypographyProps={{ fontSize: 16, fontWeight: "medium" }}
          />
        </ListItemButton>
        <ListItemButton sx={{ py: 0, minHeight: 32 }} onClick={onShare}>
          <ListItemIcon sx={{ color: "inherit" }}>
            <Share />
          </ListItemIcon>
          <ListItemText
            primary="Share the site"
            primaryTypographyProps={{ fontSize: 16, fontWeight: "medium" }}
          />
        </ListItemButton>
        <ListItemButton
          sx={{ py: 0, minHeight: 32 }}
          component="a"
          href="https://www.patreon.com/cauzzle"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ListItemIcon sx={{ color: "inherit" }}>
            <AttachMoney />
          </ListItemIcon>
          <ListItemText
            primary="Help fund development"
            primaryTypographyProps={{ fontSize: 16, fontWeight: "medium" }}
          />
        </ListItemButton>
      </List>
    </Box>
  );
}

export default About;
