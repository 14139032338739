import { useCallback } from "react";
import { writeStorage, useLocalStorage } from "@rehooks/local-storage";

function useLocalStorageState(key, initialValue) {
  const [state] = useLocalStorage(key, initialValue);

  const setState = useCallback((val) => writeStorage(key, val), [key]);

  return [state, setState];
}

export default useLocalStorageState;
